import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import fetch from "isomorphic-fetch";
import { Button, Input, Table, Typography } from "antd";
import { Address } from "../../components";

import Filters from "./Filters";
import List from "./List";
import Pagination from "./Pagination";

const Gallery = ({ address, provider, tx, writeContracts }) => {
  /** Handle pagination & limits */
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const page = Math.max(1, Number(searchParams.get("page") || 1));
  const limit = Math.min(100, Math.max(1, Number(searchParams.get("limit") || 20)));
  const offset = (page - 1) * limit;

  const gqlQuery = gql(`
    {
      nfts(first: ${limit}, skip: ${offset}, orderBy: createdAt, orderDirection: desc) {
        id
        contractAddress
        tokenId
        createdAt
        category
        farmedAt
        farmedBy
        reactions(orderBy: createdAt, orderDirection: desc) {
         id
          reactionId
          amount
          profile {
            id
          }
          createdAt
        }
        metadataUrl
        owner
        profile {
          id
        }
      }
    }
  `);
  const { loading, data, error } = useQuery(gqlQuery);

  return (
    <div>
      <Filters />

      {error && <p>Error</p>}
      {loading && <p>Loading</p>}
      {!error && !loading && (
        <List address={address} items={data?.nfts} provider={provider} tx={tx} writeContracts={writeContracts} />
      )}

      <Pagination loading={loading} data={data} page={page} limit={limit} />
    </div>
  );
};

export default Gallery;
