import React from "react";
import { DownOutlined } from "@ant-design/icons";

import { CATEGORIES } from "../../constants"

const skipCategories = ['Sports', 'Domains', 'Other']

const Filters = () => (
  <div className="nft-gallery--filters">
    <div className="nft-gallery--filters--tags">
      {CATEGORIES.filter(key => !skipCategories.includes(key)).map(key => <span key={key}>#{key}</span>)}
    </div>
    <div className="nft-gallery--filters--sorting">
      <span>
        Most Reactions$ <DownOutlined />
      </span>
    </div>
  </div>
);

export default Filters;
