import React, { useState } from "react";
import Blockies from "react-blockies";
import { Button, Tag } from "antd";

import { useLookupAddress } from "../../hooks";

import Reaction from "../../components/NFT/Reaction";

const Profile = ({ address, ensProvider }) => {
  const ens = useLookupAddress(ensProvider, address);

  if (!address) {
    return (
      <div>
        <h1>Not logged in</h1>
      </div>
    );
  }

  let displayAddress = address.substr(0, 6);
  if (ens && ens.indexOf("0x") < 0) {
    displayAddress = ens;
  }

  return (
    <div className="profile">
      <div className="profile--info">
        <div className="profile--info--avatar">
          <Blockies seed={address.toLowerCase()} size={8} scale={24} />
        </div>
        <h1>{displayAddress}<br /><Tag>Proof of Humanity verified</Tag></h1>
        <h3>0 XP | Level 0</h3>
        <h6>Total R$: 6</h6>
        <div className="profile--reactions">
          <Reaction id="1" data={{ amount: 1 }} />
          <Reaction id="3" data={{ amount: 2 }} />
          <Reaction id="0" data={{ amount: 1 }} />
          <Reaction id="5" data={{ amount: 2 }} />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Button size="large">Get R$</Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
