import React from "react";

import { REACTIONS } from "../../constants";

const Reaction = ({ className, id, data }) => {
  if (id < 0) {
    return null;
  }

  return (
    <div className={`nft-reaction ${data.voted ? "nft-reaction--voted" : ""} ${className || ''}`}>
      <img src={REACTIONS[id]?.image} alt={REACTIONS[id]?.name} />
      {Math.max(1, data.amount)}
    </div>
  );
};

export default Reaction;
