import { useEffect, useState } from "react";

const ARTBLOCKS_URL_TOKEN_REGEXP = /^https\:\/\/(www\.)?artblocks\.io\/token\/(\d+)/i;
const CRYPTOPUNKS_URL_TOKEN_REGEXP = /^https:\/\/(www\.)?larvalabs\.com\/cryptopunks\/details\/(\d+)/i;
const ETHBLOCKART_URL_TOKEN_REGEXP = /^https:\/\/(www\.?)ethblock\.art\/view\/(\d+)/i;
const ETHERSCAN_URL_TOKEN_REGEXP = /^https:\/\/([a-z0-9-\.]+\.)?etherscan\.io\/token\/(0x[a-f0-9]{40})\?a=(\d+)/i;
const FOUNDATION_URL_TOKEN_REGEXP = /^https:\/\/(www\.?)?foundation\.app\/\@[a-z0-9-\.]+\/[a-z0-9-]-(\d+)/;
const GENERIC_URL_TOKEN_REGEXP = /(\/)?(0x[a-f0-9]{40})(:|\/)((0x)?([a-f0-9]+))/i;
const SCREENSAVERWORLD_V1_URL_TOKEN_REGEXP = /^https\:\/\/(www\.)?screensaver\.world\/object\/(\d+)/i;
const SUPERRARE_V1_URL_TOKEN_REGEXP = /^https:\/\/(www\.)?superrare\.com\/artwork\/.*-(\d+)/i;
const SUPERRARE_V2_URL_TOKEN_REGEXP = /^https:\/\/(www\.)?superrare\.com\/artwork-v2\/.*-(\d+)$/i;

/**
 * Parse contract address and token from a url.
 * @function
 * @param {string} lookupUrl
 * @return {string}
 */
const getTokenIdFromUrl = lookupUrl => {
  // Strip anchors
  const url = lookupUrl.replace(/\#.*$/, "");

  // Generic lookup (OpenSea, Rarible)
  if (GENERIC_URL_TOKEN_REGEXP.test(url)) {
    const [, , contract, , id] = url.match(GENERIC_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  }

  // Etherscan
  if (ETHERSCAN_URL_TOKEN_REGEXP.test(url)) {
    const [, , contract, id] = url.match(ETHERSCAN_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  }

  // SuperRare (v1 & v2)
  if (SUPERRARE_V2_URL_TOKEN_REGEXP.test(url)) {
    const contract = "0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0";
    const [, , id] = url.match(SUPERRARE_V2_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  } else if (SUPERRARE_V1_URL_TOKEN_REGEXP.test(url)) {
    const contract = "0x41a322b28d0ff354040e2cbc676f0320d8c8850d";
    const [, , id] = url.match(SUPERRARE_V1_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  }

  // KnownOrigin (@NOTE - cannot determine contract from URL, no easy way to look up)
  if (/https:\/\/(www\.)knownorigin.io\//i.test(url)) {
    throw new Error("Unable to determine correct NFT from Known Origin.");
  }

  // Screensaver.world
  if (SCREENSAVERWORLD_V1_URL_TOKEN_REGEXP.test(url)) {
    const contract = '0x486ca491c9a0a9ace266aa100976bfefc57a0dd4';
    const [, , id] = url.match(SCREENSAVERWORLD_V1_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  }

  // Cryptopunks
  if (CRYPTOPUNKS_URL_TOKEN_REGEXP.test(url)) {
    const contract = "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb";
    const [, , id] = url.match(CRYPTOPUNKS_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  }

  // Foundation (@NOTE - Some early FND have been minted on other contracts?)
  if (FOUNDATION_URL_TOKEN_REGEXP.test(url)) {
    const contract = "0x3b3ee1931dc30c1957379fac9aba94d1c48a5405";
    const [, , id] = url.match(FOUNDATION_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  }

  // EthBlockArt
  if (ETHBLOCKART_URL_TOKEN_REGEXP.test(url)) {
    const contract = "0xb80fBF6cdb49c33dC6aE4cA11aF8Ac47b0b4C0f3";
    const [, , id] = url.match(FOUNDATION_URL_TOKEN_REGEXP);
    return [contract, id].join(":");
  }

  // ArtBlocks
  if (ARTBLOCKS_URL_TOKEN_REGEXP.test(url)) {
    throw new Error("Unable to determine correct NFT from ArtBlocks.");
    // const contract = '0x059edd72cd353df5106d2b9cc5ab83a52287ac3a';
    // const contract = '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270';
    // const [, , id] = url.match(FOUNDATION_URL_TOKEN_REGEXP);
    // return [contract, id].join(':');
  }

  // Could not detect a token ID
  return null;
};

/**
 * Parse hook for determing contract address and token id of NFT from a URL or string.
 * @function
 * @param {object} provider
 * @param {string} value
 * @return {object}
 */
const useNFTUrlParser = (provider, value = '') => {
  const [token, setToken] = useState({});

  useEffect(async () => {
    // Clear previous value
    setToken();

    if (value) {
      const reference = getTokenIdFromUrl(value);
      if (reference) {
        const [contractAddress, tokenId] = reference.split(':');
        setToken({ contractAddress, tokenId });
      }
    }
  }, [value]);

  return token;
};

export default useNFTUrlParser;
