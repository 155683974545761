import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";

import { REACTIONS } from "../../constants";

import Reaction from "./Reaction";

const ReactionOption = ({ amount = 0, disabled, id, onAdd, onRemove, quantity = 0 }) => {
  const onAddReaction = () => onAdd(id);
  const onRemoveReaction = () => onRemove(id);

  return (
    <div className="nft-reactions--react--option">
      <Reaction id={id} data={{ amount }} />
      <div className="nft-reactions--react--option--buttons">
        <Button disabled={disabled || quantity >= amount} onClick={onAddReaction}>
          +
        </Button>{" "}
        {quantity || 0}{" "}
        <Button disabled={disabled || quantity <= 0} onClick={onRemoveReaction}>
          -
        </Button>
      </div>
    </div>
  );
};

const NFTReact = ({ address, item, wallet = {}, writeContracts, tx, initialValue = {} }) => {
  const [reactions, setReactions] = useState({});

  const [isApproved, setIsApproved] = useState();
  const [isSubmitting, setIsSubmitting] = useState();
  const [isTxPending, setIsTxPending] = useState();
  const [errors, setErrors] = useState();

  const { contractAddress, tokenId } = item;

  // useEffect -> check if approved
  useEffect(async () => {
    if (address && writeContracts?.ReactionsToken) {
      try {
        const result = await writeContracts.ReactionsToken.isApprovedForAll(address, writeContracts.Registry.address);
        setIsApproved(result);
      } catch (err) {
        console.error("ERR", err);
      }
    }
  }, [address, writeContracts]);

  const addedTotal = Object.values(reactions).reduce((acc, val) => acc + val, 0);
  const total = Object.values(wallet).reduce((acc, val) => acc + val, 0);
  const availableTokens = Object.entries(wallet).filter(([, count]) => count > 0);
  const disabled = isSubmitting || isTxPending;
  const canSubmit = !disabled && addedTotal > 0;

  const onApprove = () => {
    try {
      setIsSubmitting(true);
      setIsTxPending(true);

      tx(writeContracts.ReactionsToken.setApprovalForAll(writeContracts.Registry.address, "1", {}), update => {
        // Did succeed
        if (update && (update.status === "confirmed" || update.status === 1)) {
          setIsApproved(true);
          setErrors();
        }

        setIsTxPending();
        setIsSubmitting();
      });
    } catch (err) {
      console.error("ERR", err);
      setIsSubmitting();
      setIsTxPending();
      setErrors("Could not approve");
    }
  };

  const onSubmit = () => {
    try {
      setIsSubmitting(true);
      setIsTxPending(true);

      const ids = Object.keys(reactions);
      const amounts = Object.values(reactions);

      console.log('SEND: ', contractAddress, tokenId, ids, amounts)

      tx(writeContracts.Registry.addReactions(contractAddress, tokenId, ids, amounts), update => {
        console.log(update);

        // Did succeed
        if (update && (update.status === "confirmed" || update.status === 1)) {
          setErrors();
        }

        setIsTxPending();
        setIsSubmitting();
      });
    } catch (err) {
      console.error("ERR", err);
      setIsSubmitting();
      setIsTxPending();
      setErrors("Could not approve");
    }
  };

  const onAddToken = id => {
    console.log("add");
    if (!reactions[id]) {
      reactions[id] = 0;
    }
    if (wallet[id] >= reactions[id] + 1) {
      reactions[id] += 1;
      setReactions(reactions);
    }
  };
  const onRemoveToken = id => {
    console.log("remove");
    if (reactions[id]) {
      reactions[id] -= 1;
      setReactions(reactions);
    }
  };

  if (!address) {
    return <p>Connect your wallet to react</p>;
  }

  if (total === 0) {
    return <p>You don’t own any reaction tokens</p>;
  }

  if (!isApproved) {
    return (
      <div>
        <p>You need to allow approval first (one-time approval).</p>
        <div style={{ textAlign: "center" }}>
          {isTxPending && <p>Approving...</p>}
          {!isTxPending && (
            <Button size="large" onClick={onApprove}>
              Approve
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="nft-reactions--react">
      <div>
        {availableTokens.map(([id, count]) => (
          <ReactionOption
            key={id}
            disabled={disabled}
            id={id}
            amount={count}
            quantity={reactions[id]}
            onAdd={onAddToken}
            onRemove={onRemoveToken}
          />
        ))}
      </div>
      <p>
        Adding {addedTotal} of {total}
      </p>
      <div>
        {isTxPending && <p>Reacting...</p>}
        {!isTxPending && (
          <Button style={{ width: "100%" }} disabled={!canSubmit} onClick={onSubmit}>
            Send Reaction$
          </Button>
        )}
      </div>
    </div>
  );
};

export default NFTReact;
