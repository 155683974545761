import React from "react";
import { Button, Menu, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import Account from "./Account";

const Header = ({ address, mainnetProvider, price, web3Modal, loadWeb3Modal, logoutOfWeb3Modal }) => {
  const menuContent = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/">Gallery</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/submit">Submit</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/about">About</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="page-header">
      <div>
        <h1>
          <Link to="/">Reaction$</Link>
        </h1>
      </div>

      <div className="page-header--right">
        <Account
          address={address}
          mainnetProvider={mainnetProvider}
          price={price}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
        />

        <Dropdown overlay={menuContent} placement="bottomRight">
          <Button size="large" shape="round"><MenuOutlined /></Button>
        </Dropdown>
      </div>
    </header>
  );
};

export default Header;
