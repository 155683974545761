import React from "react";
import { Col, Row } from "antd";
import TimeAgo from "react-timeago";

import Address from "../Address";

const SubmissionDetails = ({ item }) => {
  return (
    <div className="nft-card--info">
      <Row>
        <Col span={18}>
          <div className="nft-card--info--submitter">
            <Address address={item.profile?.id} copyable={false} fontSize={12} /> added{" "}
            <TimeAgo date={item.createdAt * 1000} />
          </div>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          {item.category}
        </Col>
      </Row>
    </div>
  );
};

export default SubmissionDetails;
