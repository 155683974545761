import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useThrottle } from "react-use";
import { Button, Input, Select, Spin, Form } from "antd";

import { CATEGORIES } from "../../constants";

import useNFTData from "../../hooks/NFTData";
import useNFTUrlParser from "../../hooks/NFTUrlParser";

import NFTPreview from "./NFTPreview";

const { Option } = Select;

const SubmitNFT = ({ address, provider, tx, writeContracts }) => {
  // Set token info, allow preload with query params ?contractAddress=0x0&tokenID=1
  const { contractAddress, tokenId } = Object.fromEntries(new URLSearchParams(window.location.search.substring(1)));
  const [tokenReference, setTokenReference] = useState(contractAddress && tokenId && { contractAddress, tokenId });

  // Form data
  const [nftUrl, setNFTUrl] = useState();
  const throttledNftUrl = useThrottle(nftUrl, 200);
  const [category, setCategory] = useState();

  // Track NFT URL, parsed reference, and fetched NFT data
  const inputTokenReference = useNFTUrlParser(provider, throttledNftUrl);
  useEffect(() => setTokenReference(inputTokenReference), [inputTokenReference]);

  const { data, error, loading } = useNFTData(provider, tokenReference?.contractAddress, tokenReference?.tokenId);

  // Form inputs
  const onNFTUrlChange = e => setNFTUrl(e.target.value);
  const onCategoryChange = value => setCategory(value);

  const [isSubmitting, setIsSubmitting] = useState();
  const [isTxPending, setIsTxPending] = useState();
  const [isSuccessful, setIsSuccessful] = useState();
  const [errors, setErrors] = useState();

  const disabled = isSubmitting || isTxPending;
  const canSubmit = !disabled && nftUrl && category;

  const onSubmit = async () => {
    setIsSubmitting(true);

    // @TODO - > SEE IF SUBMITTING

    try {
      tx(
        writeContracts.Registry.addNFT(
          tokenReference.contractAddress,
          Number(tokenReference.tokenId),
          "https://reactions.eth.link",
          tokenReference.contractAddress,
        ),
        update => {
          setIsTxPending(true);

          if (update?.code) {
            setIsTxPending();
            setIsSubmitting();
            setIsSuccessful();
            setErrors(update.data?.message || "Unknown error");
          } else if (update?.status === "confirmed" || update?.status === 1) {
            setIsTxPending();
            setIsSubmitting();
            setIsSuccessful(true);
            setErrors();
          }
        },
      );
    } catch (err) {
      console.error("ERR:", err);
      setErrors("Could not submit");
      setIsSubmitting();
      setIsTxPending();
    }

    // issue transaction
    // await transaction result
  };
  const onRestart = () => {
    setNFTUrl();
    setCategory();
    setTokenReference();
    setIsSuccessful();
    setIsTxPending();
    setIsSubmitting();
    setErrors();
  };

  if (!address) {
    return <p>Connect your wallet to submit.</p>;
  }

  return (
    <>
      <NFTPreview data={data} loading={loading} />

      {error && <p>Could not find a valid ERC-721 NFT. Please check the network and try again.</p>}

      {isSuccessful && (
        <div>
          <p>This NFT was successfully added to the registry.</p>
          <div>
            <Button size="large" onClick={onRestart}>
              Add another NFT
            </Button>
          </div>
        </div>
      )}

      {!isSuccessful && (
        <>
          <Form.Item>
            <Input
              style={{ width: "100%" }}
              placeholder="Paste in a URL or a NFT address"
              size="large"
              type="text"
              disabled={disabled}
              value={nftUrl}
              onChange={onNFTUrlChange}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder="Category..."
              style={{ width: "100%" }}
              size="large"
              disabled={disabled}
              onChange={onCategoryChange}
              value={category}
            >
              {CATEGORIES.map(key => (
                <Option value={key}>{key}</Option>
              ))}
            </Select>
          </Form.Item>
          {/* <div>[TAGS]</div> */}
          <Form.Item>
            {isTxPending && (
              <span>
                <Spin /> TX pending
              </span>
            )}
            {!isTxPending && (
              <Button size="large" disabled={!canSubmit} onClick={onSubmit}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            )}
          </Form.Item>
        </>
      )}
    </>
  );
};

export default SubmitNFT;
