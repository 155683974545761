import React from "react";
import { Button } from "antd";

const Pagination = ({ data, loading, limit, page }) => {
  const hasPrev = !loading && page > 1;
  const hasNext = !loading && data?.nfts?.length >= limit;

  const prevPageQuery = new URLSearchParams();
  prevPageQuery.append("page", page - 1);
  prevPageQuery.append("limit", limit);
  const nextPageQuery = new URLSearchParams();
  nextPageQuery.append("page", page + 1);
  nextPageQuery.append("limit", limit);

  return (
    <div className="nft-pagination">
      <Button disabled={!hasPrev} size="large" href={hasPrev && `/?${prevPageQuery.toString()}`}>
        Prev
      </Button>
      <Button disabled={!hasNext} size="large" href={hasNext && `/?${nextPageQuery.toString()}`}>
        Next
      </Button>
    </div>
  );
};

export default Pagination;
