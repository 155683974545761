import React from "react";

// @notice - take note of the `/matic` suffix for Polygon/Matic NFTs
const OPENSEA_BASE_URI = "https://opensea.io/assets/matic";
const REFERRAL_ADDRESS = "0x1";

const Purchase = ({ contractAddress, tokenId }) => {
  const referralUrl = `${OPENSEA_BASE_URI}/${contractAddress}/${tokenId}?ref=${REFERRAL_ADDRESS}`;

  return (
    <a target="_blank" rel="noopener referrer" href={referralUrl}>
      Purchase
    </a>
  );
};

export default Purchase;
