import React from "react";
import { Popover } from "antd";

import NFTReact from "./React";
import Reaction from "./Reaction";

const groupReactions = (items = [], address) => {
  const reactions = {};
  items.forEach(({ amount, reactionId, profile }) => {
    reactions[reactionId] = reactions[reactionId] || { amount: 0, addresses: [] };
    reactions[reactionId].amount += Math.max(0, amount);
    if (reactions[reactionId].addresses.indexOf(profile.id) === -1) {
      reactions[reactionId].addresses.push(profile.id);
    }
    if (address?.toLowerCase() === profile.id?.toLowerCase()) {
      reactions[reactionId].voted = true;
    }
  });
  return reactions;
};

const Reactions = ({ address, item, tx, writeContracts }) => {
  const reactions = groupReactions(item.reactions, address);

  // @TODO -> PULL REACTION AMOUNTS FROM WALLET
  const wallet = { 1: 1, 3: 2, 0: 1, 5: 2 };

  return (
    <div className="nft-card--reactions">
      <div className="nft-card--reactions--scores">
        {Object.entries(reactions).map(([id, data]) => (
          <Reaction key={`reaction-${id}`} id={id} data={data} />
        ))}
        <Popover
          content={
            <div style={{ width: "100%" }}>
              <NFTReact address={address} item={item} wallet={wallet} tx={tx} writeContracts={writeContracts} />
            </div>
          }
        >
          <div className="nft-card--reactions--add">+</div>
        </Popover>
      </div>
    </div>
  );
};

export default Reactions;
