import React from "react";

import ListItem from "./ListItem";

const List = ({ address, items, provider, tx, writeContracts }) => {
  if (items.length === 0) {
    return <p>No tokens.</p>;
  }

  return (
    <div className="nft-gallery">
      {items.map(item => (
        <ListItem
          key={item.id}
          address={address}
          item={item}
          provider={provider}
          tx={tx}
          writeContracts={writeContracts}
        />
      ))}
    </div>
  );
};

export default List;
