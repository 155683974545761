import { useEffect, useState } from "react";
import { ethers } from "ethers";

import ABI_ERC721 from "../data/abis/erc721";

/**
 * Fetch token data from provider
 * @function
 * @param {object} provider
 * @param {string} contractAddress
 * @param {string/integer} tokenId
 * @return {object}
 */
const fetchTokenData = async (provider, contractAddress, tokenId) => {
  const instance = new ethers.Contract(contractAddress, ABI_ERC721, provider);
  const uri = await instance.functions.tokenURI(tokenId.toString());
  return uri;
};

/**
 * Fetch token metadata from storage service.
 * @function
 * @param {string} metadataUri
 * @return {object}
 */
const fetchTokenMetadata = async metadataUri => {
  // Set URI to fetch, format correct if presented as IPFS URI
  let uri = metadataUri?.toString();
  if (/^ipfs:\/\//.test(uri)) {
    uri = uri.replace(/^ipfs:\/\//, "https://ipfs.io/ipfs/");
  }

  // Fetch result
  const result = await fetch(`https://picnic-cors-proxy.herokuapp.com/${uri}`);
  const data = await result.json();
  data.image = data?.image?.toString()?.replace(/^ipfs:\/\//, "https://ipfs.io/ipfs/");
  return data;
};

/**
 * Create hook for fetching token information from blockchain & storage service.
 * @function
 * @param {object} provider
 * @param {string} contractAddress
 * @param {string/integer} tokenId
 * @return {object}
 */
const useNFTData = (provider, contractAddress, tokenId) => {
  const [token, setToken] = useState({});

  useEffect(async () => {
    // Clear
    setToken({});

    if (contractAddress && tokenId) {
      try {
        // Set as loading
        setToken({ loading: true });

        // Get contract info from Infura
        const uri = await fetchTokenData(provider, contractAddress, tokenId);

        // Fetch data from IPFS/HTTPS
        const data = await fetchTokenMetadata(uri);
        setToken({ data });
      } catch (err) {
        console.error('ERR', err)
        // Set as error thrown
        setToken({ error: true });
      }
    }
  }, [contractAddress, tokenId]);

  return token;
};

export default useNFTData;
