import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import useNFTData from "../../hooks/NFTData";

import NFT from "./NFT";

const SingleNFT = ({ address, provider, tx, writeContracts }) => {
  const { contractAddress, tokenId } = useParams();
  const gqlQuery = gql(`
    {
      nfts(first: 1, where: { contractAddress: "${contractAddress}", tokenId: "${tokenId}" }) {
        id
        contractAddress
        tokenId
        createdAt
        category
        farmedAt
        farmedBy
        reactions(orderBy: createdAt, orderDirection: desc) {
         id
          reactionId
          amount
          profile {
            id
          }
          createdAt
        }
        metadataUrl
        owner
        profile {
          id
        }
      }
    }
  `);
  const { loading, data, error } = useQuery(gqlQuery, { pollInterval: 30000 });

  // Loading
  if (loading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  // Handle errors
  if (error) {
    return (
      <div>
        <p>Could not load NFT information.</p>
      </div>
    );
  }

  const [item] = data.nfts;
  return <NFT address={address} item={item} provider={provider} tx={tx} writeContracts={writeContracts} />;
};

export default SingleNFT;
