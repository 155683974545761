import React from "react";

import Media from "../../components/NFT/Media";

const NFTPreview = ({ data, loading }) => {
  return (
    <div className="nft-submit--preview">
      <Media loading={loading} item={{ metadata: data }} />
      <h3>{!loading && data?.name}</h3>
    </div>
  );
};

export default NFTPreview;
