import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

import useNFTData from "../../hooks/NFTData";

import Media from "../../components/NFT/Media";
import Purchase from "../../components/NFT/Purchase";
import Reactions from "../../components/NFT/Reactions";
import SubmissionDetails from "../../components/NFT/SubmissionDetails";

const ListItem = ({ address, item, provider, tx, writeContracts }) => {
  const { contractAddress, tokenId } = item || {};
  const { data, error, loading } = useNFTData(provider, contractAddress, tokenId);
  const tokenData = { contractAddress, tokenId, ...item, metadata: data };

  if (loading) {
    return (
      <div className="nft-card">
        <div className="nft-card--loading">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="nft-card">
        <SubmissionDetails item={tokenData} />
        <div className="nft-card--load-error">
          <p>Unable to load NFT details</p>
        </div>
      </div>
    );
  }

  return (
    <div className="nft-card">
      <SubmissionDetails item={tokenData} />
      <Media withLink item={tokenData} />
      <Reactions address={address} item={tokenData} tx={tx} writeContracts={writeContracts} />
      <Row className="nft-card--details">
        <Col span={18}>
          <h3>
            <Link to={`/tokens/${item.contractAddress}/${item.tokenId}`}>{tokenData.metadata?.name}</Link>
          </h3>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Purchase contractAddress={tokenData.contractAddress} tokenId={tokenData.tokenId} />
        </Col>
      </Row>
    </div>
  );
};

export default ListItem;
